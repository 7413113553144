import React, { useCallback, useMemo, useState } from "react"
import Layout from "components/layouts/Layout"
import SEO from "components/common/Seo"
import PageTitle from "components/common/ui/PageTitle"
import { Formik } from "formik"
import { Alert, Col, Container, Form, Row } from "react-bootstrap"
import SpinableButton from "components/common/ui/SpinableButton"
import FormikErrorFocus from "formik-error-focus"
import useApplyFormSubmit from "src/hooks/useApplyFormSubmit"
import * as yup from "yup"
import SuccessModal from "components/common/forms/SuccessModal"
import HrServicesConsentSubForm from "components/common/HrServicesConsentSubForm"
import { graphql } from "gatsby"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"
import { translate } from "src/utils/language"

const formInitialValues = {
  applicantName: "",
  mothersName: "",
  company: "",
  terms1: false,
  terms2: false,
  terms3: false,
  terms4: false,
  terms5: false,
}

const HrConsent = ({ data, pageContext }) => {
  const {
    page: {
      title,
      parent,
      pageAttributes: { showTitle },
    },
  } = data

  const locale = useCurrentLanguage()
  const formSettings = translate(data.formSettingsPage, locale).formSettings

  const validationMessages = formSettings.general.validationMessages

  const statement = formSettings.hrServicesForm.fieldsStatement
  const hrServicesConsentSubFormProps = useMemo(() => {
    return {
      beforeNamesText: statement.statementIntro,
      applicantNameLabel: statement.nameLabel,
      applicantNamePlaceholder: statement.namePlaceholder,
      mothersNameLabel: statement.mothersNameLabel,
      mothersNamePlaceholder: statement.mothersNamePlaceholder,
      companyLabel: statement.companyLabel,
      companyPlaceholder: statement.companyPlaceholder,
      afterNamesText: statement.statementSummary,
      checkbox1Label: statement.declareCheckboxLabel,
      checkbox1Text: statement.declareText,
      checkbox2Label: statement.agreeCheckboxLabel,
      checkbox2Text: statement.agreeText,
      checkbox3Label: statement.forwardCheckbox,
      checkbox3Text: statement.forwardText,
      checkbox4Label: statement.acknowledgeCheckboxLabel,
      checkbox4Text: statement.acknowledgeText,
      checkbox5Label: statement.understoodCheckboxLabel,
      checkbox5Text: statement.understoodText,
    }
  }, [statement])

  const {
    formId,
    submitButtonText,
    failed: { title: sendErrorText },
    success: { title: successModalHeader, description: successModalBody },
  } = formSettings.hrServicesForm

  const { applyFormSubmit, submitting, sendError } = useApplyFormSubmit()
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const toggleSuccessModal = useCallback(() => {
    setShowSuccessModal(value => !value)
  }, [])

  const handleApplyFormSubmit = useCallback(
    async (values, { resetForm }) =>
      applyFormSubmit(values, formId).then(() => {
        resetForm()

        toggleSuccessModal()
      }),
    [applyFormSubmit, formId, toggleSuccessModal]
  )

  const schema = useMemo(
    () =>
      yup.object({
        applicantName: yup.string().required(validationMessages.required),
        mothersName: yup.string().required(validationMessages.required),
        company: yup.string().required(validationMessages.required),
        terms1: yup.boolean().oneOf([true], validationMessages.required),
        terms2: yup.boolean().oneOf([true], validationMessages.required),
        terms3: yup.boolean().oneOf([true], validationMessages.required),
        terms4: yup.boolean().oneOf([true], validationMessages.required),
        terms5: yup.boolean().oneOf([true], validationMessages.required),
      }),
    [validationMessages.required]
  )

  return (
    <Layout page={pageContext?.page}>
      <SEO {...pageContext.seo} />

      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <Row>
              {showTitle && (
                <PageTitle title={title} parentNode={parent?.node} />
              )}
            </Row>
            <Formik
              onSubmit={handleApplyFormSubmit}
              validationSchema={schema}
              initialValues={{ ...formInitialValues }}
            >
              {({
                handleSubmit,
                touched,
                errors,
                setFieldValue,
                setFieldTouched,
                values,
              }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <HrServicesConsentSubForm
                      {...hrServicesConsentSubFormProps}
                    />

                    {sendError && (
                      <Alert variant="danger">{sendErrorText}</Alert>
                    )}

                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <Form.Group>
                          <SpinableButton spin={submitting} block type="submit">
                            {submitButtonText}
                          </SpinableButton>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Scroll to first error */}
                    <FormikErrorFocus
                      offset={-80}
                      align={"top"}
                      focusDelay={200}
                      ease={"linear"}
                      duration={0}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </Container>

      <SuccessModal
        show={showSuccessModal}
        header={successModalHeader}
        body={successModalBody}
        handleClose={toggleSuccessModal}
      />
    </Layout>
  )
}

export const query = graphql`
  query hrpage($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...PageTemplateFragment
    }
    formSettingsPage: wpSettingsPage(slug: { eq: "forms" }) {
      ...HrConsentFormSettingsFragment
      translations {
        ...HrConsentFormSettingsFragment
      }
    }
  }
`
export default HrConsent
