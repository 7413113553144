import React from "react"
import PropTypes from "prop-types"
import * as styles from "./HrServicesConsentSubForm.module.scss"
import WysiwygContent from "./ui/WysiwygContent"
import { Col, Form, Row } from "react-bootstrap"
import CustomFormLabel from "./forms/CustomFormLabel"
import { Field, useFormikContext } from "formik"
import RequiredCheckboxLabel from "./forms/RequiredCheckboxLabel"

// Need to outsource components here, because "as" property collides with Formik's Field "as" property
const FormControlTextarea = props => <Form.Control {...props} as="textarea" />

const HrServicesConsentSubForm = ({
  beforeNamesText,
  applicantNameLabel,
  applicantNamePlaceholder,
  mothersNameLabel,
  mothersNamePlaceholder,
  companyPlaceholder,
  afterNamesText,
  checkbox1Label,
  checkbox1Text,
  checkbox2Label,
  checkbox2Text,
  checkbox3Label,
  checkbox3Text,
  checkbox4Label,
  checkbox4Text,
  checkbox5Label,
  checkbox5Text,
}) => {
  const { touched, errors } = useFormikContext()

  return (
    <>
      <div className={styles.firstConsentWrapper}>
        <WysiwygContent
          dangerouslySetInnerHTML={{
            __html: beforeNamesText,
          }}
          className={styles.wysiwyg}
        />
      </div>
      <Row className="justify-content-center">
        <Col xs={10} sm={12} lg={6}>
          <Form.Group>
            <CustomFormLabel htmlFor="applicantName" required>
              {applicantNameLabel}
            </CustomFormLabel>
            <Field
              id="applicantName"
              name="applicantName"
              as={Form.Control}
              isInvalid={touched.applicantName && !!errors.applicantName}
              placeholder={applicantNamePlaceholder}
            />
            <Form.Control.Feedback type="invalid">
              {errors.applicantName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={10} sm={12} lg={6}>
          <Form.Group>
            <CustomFormLabel htmlFor="mothersName" required>
              {mothersNameLabel}
            </CustomFormLabel>
            <Field
              id="mothersName"
              name="mothersName"
              as={Form.Control}
              isInvalid={touched.mothersName && !!errors.mothersName}
              placeholder={mothersNamePlaceholder}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mothersName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: afterNamesText }}
        className={styles.wysiwyg}
      />
      <Form.Group>
        <Field
          name="terms1"
          type="checkbox"
          id="terms1"
          as={Form.Check}
          isInvalid={touched.terms1 && !!errors.terms1}
          feedback={errors.terms1}
          label={
            <RequiredCheckboxLabel>{checkbox1Label}</RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: checkbox1Text }}
        className={styles.wysiwyg}
      />
      <Form.Group>
        <Field
          name="terms2"
          type="checkbox"
          id="terms2"
          as={Form.Check}
          isInvalid={touched.terms2 && !!errors.terms2}
          feedback={errors.terms2}
          label={
            <RequiredCheckboxLabel>{checkbox2Label}</RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: checkbox2Text }}
        className={styles.wysiwyg}
      />
      <Form.Group>
        <Field
          name="terms3"
          type="checkbox"
          id="terms3"
          as={Form.Check}
          isInvalid={touched.terms3 && !!errors.terms3}
          feedback={errors.terms3}
          label={
            <RequiredCheckboxLabel>{checkbox3Label}</RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: checkbox3Text }}
        className={styles.wysiwyg}
      />
      <Form.Group>
        <Field
          name="terms4"
          type="checkbox"
          id="terms4"
          as={Form.Check}
          isInvalid={touched.terms4 && !!errors.terms4}
          feedback={errors.terms4}
          label={
            <RequiredCheckboxLabel>{checkbox4Label}</RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: checkbox4Text }}
        className={styles.wysiwyg}
      />
      <Form.Group>
        <Field
          id="company"
          name="company"
          as={FormControlTextarea}
          isInvalid={touched.company && !!errors.company}
          placeholder={companyPlaceholder}
        />
        <Form.Control.Feedback type="invalid">
          {errors.company}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Field
          name="terms5"
          type="checkbox"
          id="terms5"
          as={Form.Check}
          isInvalid={touched.terms5 && !!errors.terms5}
          feedback={errors.terms5}
          label={
            <RequiredCheckboxLabel>{checkbox5Label}</RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: checkbox5Text }}
        className={styles.wysiwyg}
      />
    </>
  )
}

HrServicesConsentSubForm.propTypes = {
  beforeNamesText: PropTypes.string,
  applicantNameLabel: PropTypes.string,
  applicantNamePlaceholder: PropTypes.string,
  mothersNameLabel: PropTypes.string,
  mothersNamePlaceholder: PropTypes.string,
  companyPlaceholder: PropTypes.string,
  afterNamesText: PropTypes.string,
  checkbox1Label: PropTypes.string,
  checkbox1Text: PropTypes.string,
  checkbox2Label: PropTypes.string,
  checkbox2Text: PropTypes.string,
  checkbox3Label: PropTypes.string,
  checkbox3Text: PropTypes.string,
  checkbox4Label: PropTypes.string,
  checkbox4Text: PropTypes.string,
  checkbox5Label: PropTypes.string,
  checkbox5Text: PropTypes.string,
}

export default HrServicesConsentSubForm
